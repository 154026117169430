html {
  width: 100%;
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
}

body {
  height: 100%;
  margin: 0;
  scroll-behavior: smooth;
  background-color: #000000;
  color: white;
  font-family: 'Roboto', sans-serif;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
}

#root {
  width: 100%;
}

@import '@Containers/Main/styles.scss';
