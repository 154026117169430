.main-container {
  margin: 20px;
  .opening {
    margin-top: 50px;
    font-size: 80px;
    position: relative;
    height: 300px;
    .text1 {
      position: absolute;
      right: 40%;
      width: fit-content;
    }

    .text2 {
      position: absolute;
      bottom: 0;
      left: 40%;
      width: fit-content;
    }
  }
  .experiences {
    display: flex;
    margin-top: 50px;

    .progress-bar {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 30px;
      position: relative;
      margin-left: 80px;
      .dot {
        z-index: 999;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .content {
      width: 100%;
      position: relative;
      .content-box {
        font-size: 20px;
        transform: translateY(-50%);
        position: absolute;
        img {
          margin-top: 10px;
          border-radius: 5px;
          height: 400px;
        }
      }
    }
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 0%;
  }
  25% {
    background-position: 100% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  75% {
    background-position: 0% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}
